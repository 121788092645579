import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';

export const MAX_GRID_SIZE = '1170px';
export const INNER_CONTENT_PADDING = '30px';
export const NAV_WIDTH = '200px';
export const NAV_WIDTH_SM = '85px';
export const SUB_NAV_WIDTH = '300px';
export const TOP_NAV_HEIGHT = '80px';

// Newer redesign colors can be found in colors.ts
export const COLORS = {
  inherit: 'inherit',
  aPurple: '#5a53c0',
  alizarinCrimson: '#dd1b1d',
  athensGray: '#fafafb',
  athensGrayTwo: '#ececed',
  aquaGreen: '#2fd3bc',
  aquaGreenDark: '#43c5ae',
  aquaMarine: '#3de2cb',
  aquarius: '#37ccb7',
  beeswax: '#fef3c7',
  bMedPurple: '#4845a0',
  blue4970D7: '#4970d7',
  blueberry: '#5445a0',
  bluePurp638Eff: '#637cff',
  blueGrey: '#929394',
  blueyGrey: '#939eab',
  brightCyan: '#46d4ff',
  chateau: '#9da0a3',
  burntSienna: '#e95b4b',
  cLightPurple: '#7667f9',
  cloudyBlue: '#c3c4c6',
  coralFf6C70: '#ff6c70',
  cornflower: '#6d64f5',
  cottonflower: '#7667f9',
  darkBlue152245: '#151945',
  darkBlue1A3245: '#1A3245',
  darkBlueGrey: '#828282',
  darkBlueGreyTwo: '#0d2436',
  darkFog: '#e3e8ee',
  darkGreyBlue: '#333f5b',
  darkIris: '#7667f9',
  darkMint: '#3dc47e',
  darkNavy: '#111a3d',
  darkSlateBlue: '#1d2f5e',
  darkTurquoise: '#11999e',
  darkerNavy: '#1d285e',
  darkestBlue: '#111a3d',
  disabledBlackOutlineButton: '#959ead',
  dodgerBlue: '#4592ff',
  electricBlue: '#3cc5f7',
  fadedIris: '#bdb5e4',
  fern: '#6bbf73',
  fillActionDanger: '#e95432',
  fillActionInfo: '#00b8d9',
  fillActionPrimary: '#1565d8',
  fillActionSecondary: '#3f598a',
  fillActionSuccess: '#36b37e',
  fillActionWarning: '#faad13',
  fillBlackBlack: '#183b56',
  fillBlackGray: '#5a7184',
  fillBlackGrayLight: '#b3bac5',
  fillLightActionDanger: 'rgba(233, 84, 50, 0.1)',
  fillLightActionPrimary: 'rgba(21, 101, 216, 0.1)',
  fillLightActionSuccess: 'rgba(54, 179, 126, 0.1)',
  fillLightActionWarning: 'rgba(250, 173, 19, 0.1)',
  fillLightBlackGray: 'rgba(90, 113, 132, 0.1)',
  fillLightBlackGrayLight: 'rgba(179, 186, 197, 0.1)',
  fillLightWhiteWhite: 'rgba(255, 255, 255, 0.1)',
  fog: '#d2dcea',
  fogTwo: '#e2e8ef',
  green: '#3dc47e',
  greenPrimary: '#3dc47e',
  greenyBlue: '#3bbdc4',
  grey: '#8d98a7',
  iris: '#5b53cc',
  lightBlueGrey: '#c8cad1',
  lightBluePurp81A4Ff: '#818eff',
  lightCoral: '#ff828a',
  lightGreen: '#00B67A',
  lightGrey: '#f8fafc',
  lightGreyBlue: '#a8adb9',
  lightGreyE7F0Fc: '#e7f0fc',
  lightOrange: '#ffa34d',
  lightPeriwinkle: '#dcdcde',
  lightPeriwinkleTwo: '#dce0e6',
  lightRed: '#ff4f52',
  lightRose: '#ffc7d0',
  lightstGrey: '#f4f8ff',
  linkGreen: '#43c5ae',
  mediumGrey: '#d2dcea',
  mineShaft: '#2d2d2d',
  multicloudBlack: '#252c30',
  mutedNavy3F5797: '#3f4e97',
  navyBlue1D2F5E: '#1d285e',
  neonBlue: '#88f7ee',
  neonGreen: '#88f7b7',
  neonYellowGreen: '#e6ff81',
  neutralDark5: '#dcdcde',
  neutralDark6: '#f4f5f7',
  orange: '#f87c44',
  orangePrimary: '#ffa34d',
  paleBlue: '#dce0e6',
  paleGrey: '#f8fbff',
  paleIris: '#637cff',
  palePeach: '#feedb3',
  paleSkyBlue: '#c5e4fc',
  pureYellow: '#fff43c',
  redPrimary: '#ff4f52',
  redesignPurple: '#9b5efc', //temp hack
  robinSEgg: '#63ffe9',
  rope: '#8a4d1a',
  sketchMeaXureProperty: '#f5a623',
  sketchMeaXureSpacing: '#50e3c2',
  slate: '#505a72',
  softBlue: '#5999e3',
  softGreen: '#00333E',
  silverSand: '#c3c7cc',
  teal25Dcc3: '#2fd3bc',
  tealPrimary: '#3bbdc4',
  testYellowOrange: '#ffce3c',
  turquoise: '#30e3ca',
  veryLightBlue: '#e5eaf4',
  veryLightGreen: '#ccffbf',
  veryLightPink: '#d8d8d8',
  violetPrimary: '#6553c0',
  white: '#fff',
  whiteBlue: '#f6faff',
  whiteTextOnDark: '#bdd0ff',
  whiteTwo: '#f8f8f8',
} as const;

export type COLORS = keyof typeof COLORS;

export const hexToRgba = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const fontAvenirRoman = css`
  font-family: 'Avenir-Roman', 'Arial', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

export const fontAvenirBold = css`
  font-family: 'Avenir', 'Arial Black', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

export const fontConsolas = css`
  font-family: 'Consolas', monospace;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const excludedProps = ['color', 'display', 'height', 'size', 'width'];

export const shouldForwardProp = (prop: string) => isPropValid(prop) && !excludedProps.includes(prop);

export { TIMING } from './timing';

import getConfig from 'next/config';

const publicConfig = getConfig().publicRuntimeConfig;

export const CONFIG_BASE_DOMAIN = publicConfig.BASE_DOMAIN;
export const CONFIG_GRAPHQL_URL = publicConfig.GRAPHQL_URL;
export const CONFIG_API_URL = publicConfig.API_URL;
export const CONFIG_VERSION = publicConfig.VERSION;
export const CONFIG_ENTERPRISE_ENABLED = publicConfig.ENTERPRISE_ENABLED;
export const CONFIG_INTERCOM_ENABLED = publicConfig.INTERCOM_ENABLED;
export const CONFIG_INTERCOM_APP_ID = publicConfig.INTERCOM_APP_ID;

export const CONFIG_SENTRY_ENVIRONMENT = publicConfig.SENTRY_ENVIRONMENT as
  | 'local development'
  | 'dev'
  | 'staging'
  | 'jest test'
  | 'production';

import { ClassNames, css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkProps } from 'next/link';
import React from 'react';
import * as styledSystem from 'styled-system';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { COLORS } from '@/constants/colors';
import { MQ } from '@/constants/styledTheme';
import { COLORS as COLORS_OLD, fontAvenirBold, fontAvenirRoman, shouldForwardProp } from '@/constants/styles';
import { Flex } from '@/elements/Div';

import { AnchorProps, Link } from './Anchor';

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
  styledSystem.alignItems,
  styledSystem.justifyContent,
  styledSystem.display,
  styledSystem.position,
);

const baseStyle = css`
  background-color: ${COLORS_OLD.white};
  border-radius: 20px;
  border-style: solid;
  border-color: ${COLORS_OLD.iris};
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
  transition: background-color 0.1 ease-out;
  white-space: nowrap;
  width: auto;

  &:disabled {
    cursor: not-allowed;
  }
`;

const irisFill = css`
  background-color: ${COLORS_OLD.iris};
  color: ${COLORS_OLD.white};
  fill: ${COLORS_OLD.white};

  &:visited {
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:disabled {
    background-color: ${COLORS_OLD.fadedIris};
    border-color: ${COLORS_OLD.fadedIris};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS_OLD.blueberry};
    border-color: ${COLORS_OLD.blueberry};
  }
`;

const irisOutline = css`
  background-color: ${COLORS_OLD.white};
  color: ${COLORS_OLD.iris};
  fill: ${COLORS_OLD.iris};

  &:visited {
    color: ${COLORS_OLD.iris};
    fill: ${COLORS_OLD.iris};
  }

  &:disabled {
    background-color: ${COLORS_OLD.fadedIris};
    border-color: ${COLORS_OLD.fadedIris};
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS_OLD.iris};
    border-color: ${COLORS_OLD.iris};
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }
`;

const paleGreyOutline = css`
  border-color: ${COLORS_OLD.paleBlue};
  color: ${COLORS_OLD.blueGrey};
  fill: ${COLORS_OLD.blueGrey};

  &:visited {
    color: ${COLORS_OLD.blueGrey};
    fill: ${COLORS_OLD.blueGrey};
  }

  &:disabled {
    color: ${COLORS_OLD.cloudyBlue};
    fill: ${COLORS_OLD.cloudyBlue};
    border-color: ${COLORS_OLD.paleGrey};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS_OLD.paleGrey};
  }
`;

const paleBoldGreyOutline = css`
  ${paleGreyOutline}
  border-width: 2px;
  color: ${COLORS_OLD.fillBlackBlack};
  fill: ${COLORS_OLD.fillBlackBlack};
  font-family: 'Avenir', 'Arial Black', sans-serif !important;
  font-weight: 900 !important;

  &:visited {
    color: ${COLORS_OLD.fillBlackBlack};
    fill: ${COLORS_OLD.fillBlackBlack};
  }
`;

const noOutline = css`
  border: none;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  border-color: transparent;

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    transform: none;
  }
`;

const noStyle = css`
  width: auto;
  height: unset;
  border-color: transparent;
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  color: ${COLORS_OLD.slate};
  fill: ${COLORS_OLD.slate};

  &:visited {
    color: ${COLORS_OLD.slate};
    fill: ${COLORS_OLD.slate};
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    transform: none;
  }
`;

const redesignPurple = css`
  background-color: ${COLORS.Purple[500]};
  border: none;
  color: ${COLORS.Neutral[0]};

  &:hover:not(:disabled) {
    background-color: ${COLORS.Purple[400]};
    border-color: ${COLORS.Purple[400]};
  }
`;

const aquaGreenFill = css`
  background-color: ${COLORS_OLD.aquaGreen};
  border-color: ${COLORS_OLD.aquaGreen};
  border-width: 2px;
  color: ${COLORS_OLD.white};
  fill: ${COLORS_OLD.white};

  &:visited {
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:hover:not(:disabled) {
    background-color: ${COLORS_OLD.aquaGreenDark};
    border-color: ${COLORS_OLD.aquaGreenDark};
  }
`;

const aquaMarineFill = css`
  background-color: ${COLORS_OLD.aquaMarine};
  border-color: transparent;
  border-width: 2px;
  color: ${COLORS_OLD.white};
  fill: ${COLORS_OLD.white};

  &:visited {
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: ${COLORS_OLD.aquarius};
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }
`;

const cornflowerFill = css`
  background-color: ${COLORS_OLD.cornflower};
  border-color: ${COLORS_OLD.cornflower};
  border-width: 2px;
  color: ${COLORS_OLD.white};
  fill: ${COLORS_OLD.white};

  &:visited {
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:hover:not(:disabled) {
    background-color: ${COLORS_OLD.bMedPurple};
    border-color: ${COLORS_OLD.bMedPurple};
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }
`;

const blackOutline = css`
  background-color: transparent;
  border-color: ${COLORS_OLD.fillBlackBlack};
  border-width: 2px;
  color: ${COLORS_OLD.fillBlackBlack};
  fill: ${COLORS_OLD.fillBlackBlack};

  &:visited {
    color: ${COLORS_OLD.fillBlackBlack};
    fill: ${COLORS_OLD.fillBlackBlack};
  }

  &:hover:not(:disabled) {
    border-color: ${COLORS_OLD.fillBlackBlack};
    background-color: ${COLORS_OLD.fillBlackBlack};
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:disabled {
    border-color: ${COLORS_OLD.disabledBlackOutlineButton};
    color: ${COLORS_OLD.disabledBlackOutlineButton};
    fill: ${COLORS_OLD.disabledBlackOutlineButton};
  }
`;

const whiteOutline = css`
  background-color: transparent;
  border-color: ${COLORS_OLD.white};
  border-width: 2px;
  color: ${COLORS_OLD.white};
  fill: ${COLORS_OLD.white};

  &:visited {
    color: ${COLORS_OLD.white};
    fill: ${COLORS_OLD.white};
  }

  &:hover:not(:disabled) {
    border-color: ${COLORS_OLD.white};
    background-color: ${COLORS_OLD.white};
    color: ${COLORS_OLD.fillBlackBlack};
    fill: ${COLORS_OLD.fillBlackBlack};
  }
`;

const btnTypes = {
  irisFill,
  irisOutline,
  paleGreyOutline,
  paleBoldGreyOutline,
  noOutline,
  noStyle,
  aquaGreenFill,
  aquaMarineFill,
  whiteOutline,
  cornflowerFill,
  blackOutline,
  redesignPurple,
};

const sizes = {
  small: css({ padding: '8px 20px' }),
  medium: css({ padding: '8px 20px' }),
  large: css({ padding: '8px 45px' }),
};

const StyledButton = styled('button', { shouldForwardProp })<{
  otherStyles: string;
}>`
  ${baseStyle}
  ${props => `${props.otherStyles}`}
  ${baseStyledSystem}
`;

export type BtnTypes =
  | 'irisFill'
  | 'irisOutline'
  | 'paleGreyOutline'
  | 'paleBoldGreyOutline'
  | 'noOutline'
  | 'noStyle'
  | 'aquaGreenFill'
  | 'aquaMarineFill'
  | 'cornflowerFill'
  | 'blackOutline'
  | 'whiteOutline'
  | 'redesignPurple';

type ButtonProps = Omit<
  styledSystem.ColorProps &
    styledSystem.LayoutProps &
    styledSystem.SpaceProps &
    styledSystem.PositionProps &
    styledSystem.AlignItemsProps &
    styledSystem.JustifyContentProps &
    styledSystem.DisplayProps &
    styledSystem.TypographyProps,
  'size' | 'css'
> & {
  btnType?: BtnTypes;
  color?: keyof typeof COLORS_OLD;
  full?: boolean;
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  loadingInline?: boolean;
  hasShadow?: boolean;
  mobile?: boolean;
  bold?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ btnType, children, full, size, loading, loadingInline, hasShadow, mobile, bold, ...props }, ref) => {
    const btnStyle = btnType ? btnTypes[btnType] : '';
    const fontStyle = bold ? fontAvenirBold : fontAvenirRoman;
    const sizeStyle = size ? sizes[size] : sizes.medium;
    const fullStyle = full ? css({ width: '100%' }) : '';
    const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';
    const stretchStyle = mobile
      ? css`
          width: 100%;

          ${MQ.m} {
            width: auto;
          }
        `
      : '';

    const otherStyles = [btnStyle, fontStyle, sizeStyle, fullStyle, boxShadow, stretchStyle];

    return (
      <ClassNames>
        {({ css: classNamesCss }) => (
          <StyledButton otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
            {loading && !loadingInline && <LoadingSpinner color="white" inButton={true} size="s" visible={true} />}
            {loadingInline && !loading && (
              <Flex justifyContent="center" alignItems="center">
                <LoadingSpinner
                  color="white"
                  inButton={true}
                  size="s"
                  visible={true}
                  css={css`
                    margin-left: 0;
                    margin-right: 0;
                  `}
                />
                <Flex justifyContent="center" alignItems="center" ml="7px">
                  {children}
                </Flex>
              </Flex>
            )}
            {!loading && !loadingInline && children}
          </StyledButton>
        )}
      </ClassNames>
    );
  },
);

Button.displayName = 'Button';

const StyledAnchor = styled('a', { shouldForwardProp })<{
  otherStyles: string;
}>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${baseStyledSystem}
`;

export const AnchorButton = React.forwardRef<
  HTMLAnchorElement,
  Omit<
    AnchorProps & React.ButtonHTMLAttributes<HTMLButtonElement> & React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'size'
  > &
    ButtonProps
>(({ btnType, children, full, size, hasShadow, bold, ...props }, ref) => {
  const btnStyle = btnType ? btnTypes[btnType] : '';
  const fontStyle = bold ? fontAvenirBold : fontAvenirRoman;

  const sizeStyle = size ? sizes[size] : sizes.medium;
  const fullStyle = full ? css({ width: '100%' }) : '';
  const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';

  const otherStyles = [
    btnStyle,
    fontStyle,
    sizeStyle,
    fullStyle,
    boxShadow,
    css({
      display: 'inline-flex',
      alignItems: 'center',
      textDecoration: 'none',
    }),
  ];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledAnchor hover="none" otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {children}
        </StyledAnchor>
      )}
    </ClassNames>
  );
});

AnchorButton.displayName = 'AnchorButton';

const StyledLink = styled(Link, { shouldForwardProp })<{ otherStyles: string }>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${baseStyledSystem}
`;

export const LinkButton = React.forwardRef<
  HTMLAnchorElement,
  Omit<
    LinkProps &
      AnchorProps &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'size'
  > &
    ButtonProps
>(({ btnType, children, full, size, hasShadow, bold, ...props }, ref) => {
  const btnStyle = btnType ? btnTypes[btnType] : '';
  const fontStyle = bold ? fontAvenirBold : fontAvenirRoman;
  const sizeStyle = size ? sizes[size] : sizes.medium;
  const fullStyle = full ? css({ width: '100%' }) : '';
  const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';

  const otherStyles = [
    btnStyle,
    fontStyle,
    sizeStyle,
    fullStyle,
    boxShadow,
    css({
      display: 'inline-flex',
      alignItems: 'center',
      textDecoration: 'none',
    }),
  ];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledLink hover="none" otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {children}
        </StyledLink>
      )}
    </ClassNames>
  );
});

LinkButton.displayName = 'LinkButton';

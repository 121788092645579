import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicies,
  TypePolicy,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import Router from 'next/router';

import { GraphQLErrorCodes } from '@/@types/models';
import { Toast } from '@/elements/Toast';
import { fiveHundredUrl } from '@/routes';
import { setIsLoggedIn } from '@/utils/auth';
import { capitalize } from '@/utils/strings';

import { CONFIG_GRAPHQL_URL } from '../constants/config';
import upboundIntrospectionResult from '../generated/upbound-introspection-result';

const commonUserPolicy: TypePolicy = {
  fields: {
    loginProviders: { merge: false },
    name: {
      read(_, { readField }) {
        return `${readField('firstName')} ${readField('lastName')}`;
      },
    },
  },
};

const typePolicies: TypePolicies = {
  BaseUser: commonUserPolicy,
  User: commonUserPolicy,
  CurrentUser: { ...commonUserPolicy, fields: { ...commonUserPolicy.fields, tokens: { merge: false } } },
  Mutation: { fields: { admin: { merge: true } } },
  AccountControlPlane: { keyFields: ['controlPlane', ['id']] },
  Organization: {
    fields: {
      currentUserTeams: { merge: false },
      invites: { merge: false },
      members: { merge: false },
      robots: { merge: false },
      teams: { merge: false },
    },
  },
  OrgMember: { keyFields: ['user', ['id']] },
  OrgAccount: { fields: { repositories: { merge: false } } },
  Query: { fields: { admin: { merge: true }, accounts: { merge: false }, registry: { merge: true } } },
  Robot: { fields: { teams: { merge: false }, tokens: { merge: false } } },
  ScopedTeam: { keyFields: ['id'] },
  Team: {
    fields: {
      controlPlanes: { merge: false },
      members: { merge: false },
      repositories: { merge: false },
      robots: { merge: false },
    },
  },
  TeamMember: { keyFields: ['user', ['id']] },
  TeamControlPlane: { keyFields: ['controlPlane', ['id']] },
  TeamRepository: { keyFields: ['repository', ['id']] },
  Namespace: { fields: { xrds: { merge: false } } },
};

const batchHttpLink = new BatchHttpLink({
  uri: CONFIG_GRAPHQL_URL,
  credentials: 'include',
  headers: { 'Upbound-API-Version': 'old' },
});

function hasErrorCode(gqlErrors: readonly GraphQLError[], gqlErrorCode: GraphQLErrorCodes, httpErrorCode?: string) {
  return gqlErrors.some(err => {
    const gqlCode = err.extensions && err.extensions.code;

    if (gqlCode && gqlCode === gqlErrorCode) {
      return true;
    }

    if (httpErrorCode) {
      const gqlResponse: Response | false = err.extensions && (err.extensions.response as Response);

      if (gqlResponse && gqlResponse.status.toString() === httpErrorCode) {
        return true;
      }
    }

    return false;
  });
}

export const hasLimitReachedError = (gqlErrors: readonly GraphQLError[]) => {
  return hasErrorCode(gqlErrors, GraphQLErrorCodes.LIMIT_REACHED);
};

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (!graphQLErrors) {
    return;
  }

  if (hasErrorCode(graphQLErrors, GraphQLErrorCodes.UNAUTHENTICATED, '401')) {
    setIsLoggedIn(false);
    return;
  }

  if (hasErrorCode(graphQLErrors, GraphQLErrorCodes.NOT_FOUND_ERROR, '404')) {
    // TODO: Should we go to 404 here with a  bypassOperations like for forbidden?
    return;
  }

  if (hasErrorCode(graphQLErrors, GraphQLErrorCodes.INTERNAL_SERVER_ERROR, '500')) {
    Router.push(fiveHundredUrl());
    return;
  }

  if (hasErrorCode(graphQLErrors, GraphQLErrorCodes.FORBIDDEN, '403')) {
    // Add operations here that we want to be able to get a 403 from in the use hook
    const bypassOperations: string[] = ['CreateOrgInvite'];

    if (bypassOperations.includes(operation.operationName)) {
      return;
    }

    Toast({ msg: 'Permission denied.' });
    return;
  }

  const [firstGraphQLError] = graphQLErrors;
  const { extensions } = firstGraphQLError;

  const errorBody = (extensions.response as { body?: { error?: string; message?: string } }).body;

  const errorMessage = errorBody?.error ?? errorBody?.message;
  if (errorMessage) {
    Toast({ msg: `${capitalize(errorMessage)}.` });
    return;
  }

  Toast({ msg: 'An unknown error has occurred.' });
});

function createClient() {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, batchHttpLink]),
    cache: new InMemoryCache({ possibleTypes: upboundIntrospectionResult.possibleTypes, typePolicies }),
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

const isServer = typeof window === 'undefined';
let clientSideClient: ApolloClient<NormalizedCacheObject>;

export default function getClient() {
  if (isServer) {
    return createClient();
  }

  if (!clientSideClient) {
    clientSideClient = createClient();
  }

  return clientSideClient;
}

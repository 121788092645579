import { makeVar, useReactiveVar } from '@apollo/client';
import React, { createContext, Dispatch, useContext } from 'react';

const LoggedInContext = createContext<[boolean, Dispatch<boolean>]>([false, () => undefined]);

const isLoggedInVar = makeVar<boolean | undefined>(undefined);

export function setIsLoggedIn(loggedIn: boolean) {
  isLoggedInVar(loggedIn);
}

export const LoggedInProvider: React.FC<React.PropsWithChildren<{ isLoggedIn: boolean }>> = ({
  children,
  isLoggedIn: initialIsLoggedIn,
}) => {
  if (isLoggedInVar() === undefined) {
    setIsLoggedIn(initialIsLoggedIn);
  }
  const isLoggedIn = useReactiveVar(isLoggedInVar) as boolean;

  return <LoggedInContext.Provider value={[isLoggedIn, setIsLoggedIn]}>{children}</LoggedInContext.Provider>;
};

export function useIsLoggedIn() {
  return useContext(LoggedInContext);
}

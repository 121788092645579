import faviconIco from './favicon.ico';
import favicon16Png from './favicon-16.png';
import favicon32Png from './favicon-32.png';
import favicon96Png from './favicon-96.png';
import favicon120Png from './favicon-120.png';
import favicon128Png from './favicon-128.png';
import favicon152Png from './favicon-152.png';
import favicon180Png from './favicon-180.png';
import favicon192Png from './favicon-192.png';
import favicon196Png from './favicon-196.png';
import favicon270Png from './favicon-270.png';

export const favicon = faviconIco.src;
export const favicon16 = favicon16Png.src;
export const favicon32 = favicon32Png.src;
export const favicon96 = favicon96Png.src;
export const favicon120 = favicon120Png.src;
export const favicon128 = favicon128Png.src;
export const favicon152 = favicon152Png.src;
export const favicon180 = favicon180Png.src;
export const favicon192 = favicon192Png.src;
export const favicon196 = favicon196Png.src;
export const favicon270 = favicon270Png.src;

export const rootUrl = () => '/';
export const fiveHundredUrl = () => '/500';
export const fourHundredUrl = () => '/404';
export const loginUrl = (returnTo?: string) => `/login${returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''}`;
export const logoutUrl = () => '/logout';
export const registerUrl = () => '/register';
export const resetPasswordUrl = () => '/resetPassword';
export const settingsUrl = () => '/settings';
export const settingsProfileUrl = () => '/settings/profile';
export const settingsEmailUrl = () => '/settings/email';
export const settingsPasswordUrl = () => '/settings/password';
export const settingsOrgsUrl = () => '/settings/organizations';
export const settingsTokensUrl = () => '/settings/tokens';
export const settingsProvidersUrl = () => '/settings/providers';
export const settingsDeleteUrl = () => '/settings/delete';
export const orgSettingsUrl = (orgName: string) => `/o/${encodeURIComponent(orgName)}`;
export const orgProfileUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/profile`;
export const orgUsersUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/users`;
export const orgTeamsUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/teams`;
export const orgTeamUrl = (orgName: string, teamId: string) =>
  `${orgSettingsUrl(orgName)}/teams/${encodeURIComponent(teamId)}`;
export const orgRobotsUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/robots`;
export const orgRobotCreateUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/robots/create`;
export const orgRobotUrl = (orgName: string, robotId: string) =>
  `${orgSettingsUrl(orgName)}/robots/${encodeURIComponent(robotId)}`;
export const orgSSOProviderUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/ssoProvider`;
export const orgBillingUrl = (orgName: string) => `${orgSettingsUrl(orgName)}/billing`;
export const selectOrgUrl = () => '/selectOrg';
export const createOrgUrl = () => '/createOrg';

import { FieldProps } from 'formik';
import { Props as SelectProps } from 'react-select';

import {
  ControlPlanePermission,
  Maybe,
  OrganizationPermission,
  RepositoryPermission,
  TeamControlPlaneFragment,
  TeamRepositoryFragment,
} from '@/generated/upbound-graphql';

export type Defined = string | number | boolean | symbol | bigint | object;

export interface TValue {
  label: string;
  value: string;
}

export interface RegisterPayload {
  username?: string;
  firstName: string;
  lastName: string;
  password?: string;
  emailClaimId?: string;
  organizationInvite?: Maybe<string>;
  oauthIdentity?: string;
  terms?: boolean;
}

export interface RegisterValues {
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  terms?: boolean;
  optInMarketing?: boolean;
  oauthIdentity?: string;
  country: string | null;
  state: string | null;
}

export interface PrepopulationValues {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  country: string | undefined;
  state: string | undefined;
}

export interface NewPasswordValues {
  currentPass: string;
  newPass: string;
  newPassConf: string;
}

export interface CreateOrgValues {
  name: string;
  displayName: string;
}

export interface LoginValues {
  id: string;
  password: string;
  remember: boolean;
  organizationInvite?: string;
}

export interface LoginSSOValues {
  email: string;
}

export interface ResetValues {
  password: string;
  token: string;
}

export interface CreateOrgValues {
  name: string;
  displayName: string;
}

export interface InviteValues {
  email: string;
  role: OrganizationPermission;
}

export interface OpenAuthUserData {
  AccountName: string;
  Email: string;
  FirstName: string;
  ID: string;
  LastName: string;
  Provider: string;
  Username: string;
}

export enum SentryEnvironments {
  Local = 'local development',
  Development = 'dev',
  Staging = 'staging',
  Testing = 'jest test',
  Production = 'production',
}

export interface AxiosOptions {
  verbose?: boolean;
}

export enum GraphQLErrorCodes {
  CONFLICT = 'CONFLICT', // 409
  FORBIDDEN = 'FORBIDDEN', // 403
  LIMIT_REACHED = 'LIMIT_REACHED', // 435
  MISSING_DEPS = 'MISSING_DEPS', // Custom
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR', // 404
  UNAUTHENTICATED = 'UNAUTHENTICATED', // 401
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY', // 422
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR', // 500
}

export type SetCpPermissionArgs = {
  controlPlaneId: string;
  permission: ControlPlanePermission;
  callbackFn?: () => void;
};
export type SetRepoPermissionArgs = { repositoryId: string; permission: RepositoryPermission; callbackFn?: () => void };

export type CpPermissionCard = { controlPlane: TeamControlPlaneFragment };
export type RepoPermissionCard = { repository: TeamRepositoryFragment };
export type CpOrRepoPermissionCard = CpPermissionCard | RepoPermissionCard;

export interface RobotValues {
  description: string;
  name: string;
}

export type SelectFieldProps = SelectProps &
  FieldProps<Omit<SelectProps, 'options'>> & {
    options?: TValue[];
  };

// This is copy of the shared colors/shades constants in our monorepo.
// This is a temporary solution to support our redesign colors in Accounts.
// TODO: Support Accounts in our monorepo so this project can use the shared packages.

const COLORS = {
  Blue: {
    100: '#d3eafd',
    200: '#a6d5fa',
    300: '#7ac0f8',
    400: '#4dabf5',
    500: '#2196f3',
    600: '#1b79c9',
    700: '#155da0',
    800: '#0f4077',
    900: '#09244e',

    get Primary() {
      return this[500];
    },
  } as const,
  Green: {
    100: '#dbefdc',
    200: '#b7dfb9',
    300: '#94cf96',
    400: '#70bf73',
    500: '#4caf50',
    600: '#3e8d47',
    700: '#2f6c3e',
    800: '#204a36',
    900: '#11292d',

    get Primary() {
      return this[500];
    },
  } as const,
  Lime: {
    100: '#ebf1d9',
    200: '#d7e4b4',
    300: '#c3d68e',
    400: '#afc969',
    500: '#9bbb43',
    600: '#7d973d',
    700: '#5e7336',
    800: '#404f31',
    900: '#212b2a',

    get Primary() {
      return this[500];
    },
  } as const,
  Neutral: {
    0: '#ffffff',
    100: '#e5e6e9',
    200: '#cdcdd3',
    300: '#b3b4bd',
    400: '#9a9ca7',
    500: '#818391',
    600: '#686a7c',
    700: '#4e5165',
    800: '#353950',
    900: '#1c1f39',
    1000: '#030724',

    get Primary() {
      return this[500];
    },
  } as const,
  Orange: {
    100: '#fce7d7',
    200: '#f9cfae',
    300: '#f5b686',
    400: '#f29e5d',
    500: '#ef8635',
    600: '#c06c31',
    700: '#90532e',
    800: '#623a2b',
    900: '#322128',

    get Primary() {
      return this[500];
    },
  } as const,
  Purple: {
    100: '#ebdffe',
    200: '#d7bffe',
    300: '#c39efd',
    400: '#af7efd',
    500: '#9b5efc',
    600: '#7d4cd1',
    700: '#5e3ba5',
    800: '#402a7b',
    900: '#21194f',

    get Primary() {
      return this[500];
    },
  } as const,
  Red: {
    100: '#fdd9d7',
    200: '#fbb4af',
    300: '#f88e86',
    400: '#f6695e',
    500: '#f44336',
    600: '#c43732',
    700: '#932b2e',
    800: '#641f2c',
    900: '#331328',

    get Primary() {
      return this[500];
    },
  } as const,
  Teal: {
    100: '#d7ecec',
    200: '#afdada',
    300: '#87c7c7',
    400: '#5fb5b5',
    500: '#37a2a2',
    600: '#2d8389',
    700: '#22646f',
    800: '#184557',
    900: '#0d263d',

    get Primary() {
      return this[500];
    },
  } as const,
  Yellow: {
    100: '#fbf4d7',
    200: '#f7e9ae',
    300: '#f2de86',
    400: '#eed35d',
    500: '#eac835',
    600: '#bca131',
    700: '#8d7b2e',
    800: '#60542b',
    900: '#312e28',

    get Primary() {
      return this[500];
    },
  } as const,
} as const;

const SHADES = {
  Blue: {
    25: '#061538',
    50: '#e9f4fe',
    75: '#def0fe',
    150: '#bcdffb',
    850: '#0c3262',
    950: '#061538',
  } as const,
  Green: {
    25: '#f6fbf6',
    50: '#edf7ed',
    75: '#e4f3e5',
    150: '#c9e7ca',
    850: '#193a31',
    950: '#0b1828',
  } as const,
  Neutral: {
    25: '#f8f9f9',
    50: '#f2f2f4',
    75: '#ecedef',
    150: '#d9dade',
    850: '#292c45',
    950: '#10142f',
  } as const,
  Orange: {
    25: '#fef9f5',
    50: '#fdf3eb',
    75: '#fdede1',
    150: '#fadac2',
    850: '#4a2d29',
    950: '#1b1325',
  } as const,
  Purple: {
    25: '#faf6fe',
    50: '#f5eefe',
    75: '#f0e7ff',
    150: '#e1cefe',
    850: '#312165',
    950: '#130f39',
  } as const,
  Red: {
    25: '#fef5f5',
    50: '#feeceb',
    75: '#fee3e1',
    150: '#fbc6c2',
    850: '#4b1929',
    950: '#1b0d25',
  } as const,
  Yellow: {
    25: '#FEFCF5',
    50: '#FDF9EB',
    75: '#FCF7E1',
    150: '#F8EEC2',
    850: '#484129',
    950: '#131927',
  } as const,
} as const;

export { COLORS, SHADES };

type AllValuesOf<T> = T extends any ? T[keyof T] : never;
export type TextColors = AllValuesOf<AllValuesOf<typeof COLORS>> | AllValuesOf<AllValuesOf<typeof SHADES>>;

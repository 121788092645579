import { Header } from '../Header';
import { TabLinkStyled } from './styles';

type TabProps = {
  isSelected: boolean;
  title: string;
  hash: string;
};

export const HashRouteTab: React.FC<TabProps> = ({ isSelected, title, hash }) => {
  return (
    <TabLinkStyled className={isSelected ? 'selected' : ''} href={hash}>
      <Header type="h6">{title}</Header>
    </TabLinkStyled>
  );
};

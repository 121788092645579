import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import * as styledSystem from 'styled-system';

import { Box, Flex } from '@/elements/Div';

import { COLORS, TIMING } from '../../constants/styles';

interface Props extends styledSystem.LayoutProps {
  color?: COLORS;
  customSize?: number;
  inButton?: boolean;
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'custom';
  visible: boolean;
  className?: string;
}

const getDashValue = (radius: number, percent: number) => {
  const circumference = 2 * 3.1415927 * radius;
  const percentAsDecimal = percent / 100;

  return circumference * percentAsDecimal;
};

const loadingSpinnerProps = (props: Props) => {
  const borderColor = `${COLORS[props.color || 'slate']}`;
  let size = 28; // 'm' size default

  switch (props.size) {
    case 'xxs':
      size = 12;
      break;
    case 'xs':
      size = 16;
      break;
    case 's':
      size = 20;
      break;
    case 'l':
      size = 44;
      break;
    case 'xl':
      size = 64;
      break;
    case 'custom':
      size = props.customSize || size;
      break;
    case 'm':
    default:
      break;
  }

  return {
    borderColor,
    inButton: props.inButton || false,
    height: size,
    width: size,
  };
};

const svgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`;

const circleAnimation = (radius: number) => keyframes`
  0%,
  25% {
    stroke-dashoffset: ${getDashValue(radius, 97)};
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: ${getDashValue(radius, 25)};
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: ${getDashValue(radius, 97)};
    transform: rotate(360deg);
  }
`;

const baseStyle = (props: Props, radius: number) => css`
  width: ${loadingSpinnerProps(props).width}px;
  height: ${loadingSpinnerProps(props).height}px;
  margin: 0 auto;
  position: relative;
  top: ${!loadingSpinnerProps(props).inButton ? '50%' : null};
  transform: ${!loadingSpinnerProps(props).inButton ? 'translateY(-50%)' : null};
  opacity: 0;
  visibility: hidden;
  transition: opacity ${TIMING.fast()} ease-in-out,
    visibility ${TIMING.fast()} ease-out 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity ${TIMING.fast()} ease-in-out;
  }

  svg {
    animation: 2s linear infinite ${svgAnimation};
    max-width: ${loadingSpinnerProps(props).width}px;

    circle {
      animation: 1.4s ease-in-out infinite both ${circleAnimation(radius)};
      fill: transparent;
      stroke: ${loadingSpinnerProps(props).borderColor};
      stroke-dasharray: ${getDashValue(radius, 100)};
      stroke-linecap: round;
      stroke-width: ${props.size === 'xxs' || props.size === 'xs' ? 1.5 : 2}px;
      transform-origin: 50% 50%;
    }
  }
`;

export const LoadingSpinner: React.FC<Props> = props => {
  const radius = loadingSpinnerProps(props).width / 2 - (props.size === 'xxs' || props.size === 'xs' ? 0.75 : 1);
  const viewBox = `0 0 ${loadingSpinnerProps(props).width} ${loadingSpinnerProps(props).width}`;

  const style = baseStyle(props, radius);

  return (
    <Box className={`${props.className}${props.visible ? ' active' : ''}`} css={style}>
      <svg
        width={loadingSpinnerProps(props).width}
        height={loadingSpinnerProps(props).height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={loadingSpinnerProps(props).width / 2} cy={loadingSpinnerProps(props).height / 2} r={radius} />
      </svg>
    </Box>
  );
};

export const LoadingSpinnerWrapper = styled(Flex)<{ small?: boolean }>`
  justify-content: center;
  flex-grow: 1;
  min-height: ${props => (props.small ? '300px' : '400px')};
  max-height: ${props => (props.small ? '450px' : '550px')};
  height: 70vh;
`;

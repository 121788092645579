import { CONFIG_INTERCOM_APP_ID, CONFIG_INTERCOM_ENABLED } from '@/constants/config';

let savedUserInfo: Record<string, unknown> | (() => void);

const Intercom = (command: string, options?: Record<string, unknown> | (() => void)) => {
  const enabled = CONFIG_INTERCOM_ENABLED;
  if (enabled) {
    const APP_ID = CONFIG_INTERCOM_APP_ID;
    const intercom = (
      window as unknown as { Intercom: (command: string, options?: Record<string, unknown> | (() => void)) => void }
    ).Intercom;
    if (APP_ID && intercom) {
      switch (command) {
        case 'onShow':
        case 'onHide':
          intercom(command, options);
          break;
        case 'shutdown':
          intercom(command);
          break;
        default:
          intercom(command, {
            app_id: APP_ID,
            ...(options || {}),
          });
      }
    }
  }
};

function IntercomUpdate(options?: Record<string, unknown> | (() => void)) {
  Intercom('update', options);
}

export function IntercomSetUser(userInfo: Record<string, unknown> | (() => void)) {
  IntercomUpdate(userInfo);
}

// TODO: Should only allow boot to be called once
export function IntercomBoot() {
  Intercom('boot', savedUserInfo);
}

export function IntercomOpen() {
  Intercom('show');
}

export function IntercomShutdown() {
  Intercom('shutdown');
}

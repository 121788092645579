import axios, { AxiosInstance } from 'axios';

import { CONFIG_API_URL } from '../constants/config';

const API_VERSION = 'v1';
let apiInstance: AxiosInstance;

export const api = () => {
  if (!apiInstance) {
    const BASE_URL = CONFIG_API_URL;

    apiInstance = axios.create({
      baseURL: BASE_URL + '/' + API_VERSION,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  return apiInstance;
};
